.App {
  text-align: center;
  font-family: Roboto Serif;
  background: #000;
  height: 100vh;
  padding-bottom: 60px;
}

.logo {
  height: 90px;
  width: 110px;
  pointer-events: none;
}

.app-header {
  padding: 40px;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 64px !important;
  color: #ffffff;
  gap: 40px;
  font: 2rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.calender-container {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  overflow-y: scroll;
  margin-left: 15px;
  min-height: 240px;
}

.calendar {
  -webkit-box-direction: normal !important;
  -webkit-box-orient: vertical !important;
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}

.calender-month {
  -webkit-box-align: center !important;
  padding: 12px 0 12px 0 !important;
  align-items: center !important;
  display: flex !important;
  color: #ffffff;
}

.month {
  border: 1px solid #ddd;
  margin: 10px;
  padding: 10px;
  text-align: center;
}

.day-names {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  list-style: none !important;
  padding-left: 0px !important;
  margin: 0px !important;
  display: flex !important;
}

.day-name {
  color: rgb(113, 113, 113) !important;
  float: left !important;
  width: calc(14.2857%) !important;
  padding-left: 24px !important;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  color: #ffffff;
}

.day {
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #ffffff;
}

.available {
  background-color: #0c330c;
}

.unavailable {
  background-color: #808080;
}

.carousel-container {
  overflow: hidden;
  position: relative;
  margin: 40px 5px;
  max-height: 350px;
  max-width: 100vw;
  overflow-y: scrool;
}

.image-carousel {
  display: flex;
  transition: transform 1s ease; /* Adjust the transition duration as needed */
}

.carousel-image {
  width: 100%;
  height: auto;
}

.nav-links-container {
  gap: 40px;
  display: flex;
  justify-content: center;
}

.link {
  color: #ffffff !important;
  text-decoration: none !important;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  max-width: 100dvw;
  max-height: 350px;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  max-width: 100vw;
  max-height: 350px;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  max-height: 350px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  margin: 0px 4px;
  min-height: 1px;
  max-height: 350px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  max-height: 350px;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 9;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 0px;
}
[dir="rtl"] .slick-prev {
  right: 0px;
  left: auto;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 0px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

@media screen and (max-width: 890px) {
  .app-header {
    font-size: 1.5rem !important;
  }
  .nav-links-container {
    gap: 15px;
  }
}

@media screen and (max-width: 768px) {
  .App {
    /* width: 100vh; */
    align-items: flex-start;
    height: 100%;
  }
  .slick-track {
    max-height: 250px;
  }
  .slick-slide {
    max-height: 250px;
    min-height: 250px;
  }
  .slick-list {
    max-height: 250px;
  }
  .slick-slide img {
    min-height: 250px;
  }
  .carousel-container {
    max-height: 250px;
  }
}
